import React from "react";

const SvgLogo = () => {
  return (
    <svg
      width="150"
      height="100"
      viewBox="0 0 1588 1123"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M197.001 614.997C197.001 614.997 161.273 500.293 176.317 410.033C191.359 319.774 206.402 242.678 262.814 225.754C319.226 208.83 345.553 246.438 345.553 310.372C345.553 374.306 262.878 400.632 259.086 436.358C255.293 472.086 324.867 498.412 336.15 451.402C347.433 404.392 313.586 378.066 360.595 325.416C407.606 272.764 529.831 291.568 512.907 361.142C495.985 430.718 405.725 457.044 392.562 524.738C379.399 592.432 364.357 620.638 388.802 714.658C413.246 808.678 307.945 863.21 291.021 806.798C274.097 750.386 322.767 716.538 343.562 660.126C364.357 603.714 383.799 529.894 381.75 507.344C378.93 476.317 330.509 494.657 307.945 525.681C285.379 556.705 251.533 631.921 238.37 556.705C231.726 518.738 229.393 495.624 228.713 482.042C227.774 463.304 224.198 444.75 217.299 427.302C209.917 408.628 192.569 394.117 188.539 428.368C181.017 492.301 185.718 564.226 197.001 614.997Z"
        fill="currentColor"
      />
      <path
        d="M403.281 563.822C390.237 610.726 388.373 684.637 417.595 743.718C446.819 802.801 495.985 829.658 582.773 847.703C628.814 857.277 726.294 839.603 742.811 787.611C759.33 735.618 732.702 681.091 694.583 699.998C656.466 718.905 671.639 814.79 581.502 821.167C512.75 826.031 473.835 796.893 458.422 775.623C443.007 754.354 446.819 721.267 514.161 728.357C581.502 735.447 633.47 759.531 636.137 722.449C639.835 671.027 587.854 644.461 534.49 664.549C481.125 684.637 446.819 711.814 430.302 686.999C413.783 662.185 414.497 597.435 459.317 566.577C545.345 507.345 677.109 630.739 659.579 504.523C652.527 453.753 584.147 422.022 514.503 440.784C466.369 453.752 422.339 495.287 403.281 563.822Z"
        fill="currentColor"
      />
      <path
        d="M671.643 454.483C671.951 472.964 681.682 490.067 696.895 500.567C728.25 522.207 780.678 548.241 800.61 642.761C810.643 690.345 779.447 779.505 744.107 828.073C708.766 876.641 788.962 928.693 831.098 883.491C873.235 838.288 859.642 762.949 848.768 727.336C837.894 691.721 790.738 562.345 723.718 475.296C688.68 429.787 671.362 437.612 671.643 454.483Z"
        fill="currentColor"
      />
      <path
        d="M694.837 414.264C721.906 401.525 744.197 376.185 711.761 349.391C679.323 322.595 597.526 328.236 603.167 374.776C608.809 421.316 670.862 425.547 694.837 414.264Z"
        fill="currentColor"
      />
      <path
        d="M960.815 439.931C948.836 448.549 938.471 459.159 929.426 470.818C918.838 484.463 896.164 509.121 871.515 507.619C837.54 505.553 809.898 480.765 807.611 448.563C805.324 416.363 836.391 376.846 875.978 366.665C915.563 356.483 1026.26 333.341 1066.83 400.606C1090.2 439.342 1088.21 479.919 1025.69 548.286C963.16 616.651 915.714 642.761 917.595 689.669C920.643 765.725 1031.7 726.873 1068.09 689.626C1104.47 652.381 1107.95 632.329 1128.72 613.345C1184.36 562.499 1242.02 648.071 1184.61 677.446C1144.18 698.13 1090.64 607.811 1026.66 792.15C997.042 877.473 879.691 834.783 859.842 732.918C851.78 691.549 881.563 639.993 914.958 617.353C962.1 585.391 996.758 564.401 1012.56 547.463C1030.28 528.461 1055.48 492.114 1046 453.753C1038.81 424.649 1006.14 407.321 960.815 439.931Z"
        fill="currentColor"
      />
      <path
        d="M1229.14 221.902C1197.71 220.875 1166.49 229.896 1141.9 249.506C1121.39 265.867 1101.45 291.984 1094.17 333.342C1077.02 430.652 1105.13 531.198 1202.54 591.962C1262.75 629.516 1384.94 640.171 1417.66 605.283C1452.39 568.244 1423.95 523.168 1380.24 549.652C1333.81 577.794 1308.51 614.828 1234.52 588.662C1180.86 569.69 1145.82 532.818 1134.8 501.356C1123.78 469.895 1144.1 439.826 1177.16 441.236C1210.22 442.647 1263.19 552.474 1318.19 503.114C1348.96 475.499 1333.7 402.982 1249.08 400.162C1212.34 398.936 1215.57 404.728 1156 405.802C1137.98 406.127 1109.24 385.091 1124.75 310.104C1135.1 260.078 1215.48 221.987 1249.08 316.954C1281.52 408.623 1401.11 402.259 1387.04 322.831C1377.29 267.783 1306.36 224.424 1229.14 221.902Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default SvgLogo;
